/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.antzoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #d0021b;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #ffab2b;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #4d7cfe;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #6dd230;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #6dd230;
}
.ant-message-error .anticon {
  color: #d0021b;
}
.ant-message-warning .anticon {
  color: #ffab2b;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #4d7cfe;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #d0021b;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #d0021b;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-color: transparent #fff #fff transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #ffba00;
  background-color: #fffce6;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #ffba00;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #ffba00;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #6dd230;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #d0021b;
}
.ant-progress-status-exception .ant-progress-text {
  color: #d0021b;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #d0021b;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #6dd230;
}
.ant-progress-status-success .ant-progress-text {
  color: #6dd230;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #6dd230;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #d0021b;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #6dd230;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-item-danger.ant-menu-item {
  color: #d0021b;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #d0021b;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #d0021b;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #d0021b;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #d0021b;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #d0021b;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #ffba00;
  background-color: #d0021b;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: white;
  font-size: 19;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #fffce6;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 19;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #ffba00;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f8f9fa;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: white;
}
.ant-menu-item a:hover {
  color: #ffba00;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: white;
}
.ant-menu-item > .ant-badge a:hover {
  color: #ffba00;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #ffba00;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #ffba00;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f8f9fa;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 19;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #fffce6;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 4px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 4px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: white;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #ffba00;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #ffba00;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #ffba00;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #ffba00;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: white;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ffba00;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #ffba00;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 0 solid #ffba00;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 64px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 16px;
  overflow: hidden;
  line-height: 64px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 64px;
  line-height: 64px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 75px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 64px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 64px;
  line-height: 64px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #ffba00;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: white;
  background: transparent;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: transparent;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: white;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #ffba00;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #ffba00;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #ffba00;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #ffba00;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #ffba00;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-drawer-header-close-only {
  padding-bottom: 0;
  border: none;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 16px;
  height: 16px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #6dd230;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #d0021b;
}
.ant-badge-status-warning {
  background-color: #ffab2b;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 16px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #ffba00;
  border-radius: 4px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl .ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #e8ecef;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #ffba00;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #e8ecef;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #e8ecef;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #d99800;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #ffcd29;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #ffcd29;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #d0021b;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #d0021b;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #e8ecef;
  border-radius: 4px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #d0021b;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #ffe7e6;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #d0021b;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #e8ecef;
  border-bottom: 0;
  border-radius: 4px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e8ecef;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #e8ecef;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 4px 4px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #e8ecef;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}

